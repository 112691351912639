<template>
  <div class="home">
    <p class="center">
      COMING SOON
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {

  }
}
</script>

<style>
body {
  background-image: radial-gradient(circle, rgb(50,50,50), rgb(30,30,30));
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  height: 200px;
  width: 400px;
  /* background: black; */

  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  /* height: 100%; */
  /* border: 3px solid green; */
}
</style>
